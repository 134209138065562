import React, { useState, useEffect } from 'react';
import 'animate.css';

export default function MoreContentArrow() {
    const [isVisible, setIsVisible] = useState(true);
    const [animate, setAnimate] = useState(true);

    const handleScroll = () => {
        if (window.scrollY > 0) {
            setIsVisible(false);
            setAnimate(false);
        } else {
            setIsVisible(true);
            setAnimate(true);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        let interval: NodeJS.Timeout | undefined;
        if (isVisible) {
            interval = setInterval(() => {
                setAnimate(false);
                setTimeout(() => setAnimate(true), 0); // Trigger re-animation
            }, 9000);
        } else if (interval) {
            clearInterval(interval);
        }
        return () => {
            if (interval) clearInterval(interval);
        };
    }, [isVisible]);

    return (
        <div className="position-absolute bottom-0 left-0 w-100 text-center">
            <i
                className={`fas fa-chevron-down fs-1 p-3 text-white ${
                    animate ? 'animate__animated animate__fadeInDown' : ''
                }`}
            ></i>
        </div>
    );
};