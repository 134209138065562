import { Button, Card, Col, Container, Row } from "react-bootstrap";
import Reviews from "./components/Reviews";
import COMIDA_IMG from '../assets/nuevas/IMG_1530.jpg';

export default function Carta() {
    return (
        <Container id="carta" className="pt-5">
            <div className="display-5 fw-medium text-center">Tapas, empanadas y más...</div>
            <Row className="my-5">
                <Col md={12}>
                    <Card
                        className="w-100 rounded-4 border-0 mx-auto shadow"
                        style={{
                            height: "300px",
                            backgroundImage: `url(${COMIDA_IMG})`,
                            backgroundSize: "100% auto", // Ajusta el tamaño de la imagen al 100% del ancho y alto del contenedor
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center center"
                        }}>
                    </Card>
                </Col>
            </Row>
            <div>
                <p className="lead fw-medium">
                    Déjate seducir por nuestra selección de sabores
                </p>
                <p className="lead">
                    Disfruta de nuestras auténticas <b>empanadas argentinas</b> de <b>carne cortada a cuchillo y espinaca</b>, junto a <b>tapas clásicas</b> como <b>patatas bravas con alioli, fingers de pollo y boquerones de Santoña</b>.
                </p>
                <p className="lead">
                    Acompaña tu comida con una selección de <b>quesos gourmet</b> y una copa de <b>vino español</b> como <b>Rioja, Ribera o Albariño</b>. Y no te pierdas nuestras <b>raciones especiales</b>, desde las clásicas <span className="fw-medium">alitas de pollo con salsa barbacoa</span> hasta nuestras <b className="text-primary">especialidades OLBAR</b>.
                </p>
            </div>
            <p className="lead text-center fw-medium">Explora todo lo que tenemos para ofrecer y descubre nuevas experiencias gastronómicas.</p>
            <div className="text-center my-5">
                <Button
                    href="carta.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    VER CARTA EN PDF <i className="fa-regular fa-file-lines ms-2"></i>
                </Button>
            </div>
            <Row>
                <Reviews />
            </Row>
        </Container>
    );
}