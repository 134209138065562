import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Contacto from './sections/Contacto';
import Home from './sections/Home';
import Footer from './sections/components/Footer';
import CustomNavbar from './sections/components/Navbar';
import Comercial from './sections/Comercial';
import Carta from './sections/Carta';
import PrivacyPolicy from './sections/PoliticaPrivacidad';
import CookiePolicy from './sections/PoliticaCookies';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={
          <>
            <CustomNavbar />
            <Home />
            <Comercial />
            <Carta />
            <Contacto />
            <Footer />
          </>
        } />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        {/* <Route path="/legal-notice" element={<LegalNotice />} /> */}
        <Route path="/cookie-policy" element={<CookiePolicy />} />
      </Routes>
    </Router>
  );
}

export default App;