import { Container } from "react-bootstrap";
import CustomNavbar from "./Navbar";
import Footer from "./Footer";

interface BasicPageProps {
    children: React.ReactNode;
}

const BasicPage: React.FC<BasicPageProps> = ({ children }: BasicPageProps) => (
    <div className="d-flex flex-column min-vh-100">
        <CustomNavbar />
        <Container className="d-flex flex-column flex-grow-1 justify-content-between mt-5">
            {children}
        </Container>
        <Footer />
    </div>
);

export default BasicPage;