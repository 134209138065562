import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Card, Carousel, Col, Row } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';

const GOOGLE_PLACES_API_KEY = 'AIzaSyDPU9FtYSwh_mpLmn3ik-CFvd4Cw7MlXJs';
const PLACE_ID = 'ChIJJ9G3BgApQg0ROkuk2DQ0MLg'; // Reemplaza con el place_id del lugar

interface ReviewItem {
    author_name: string;
    profile_photo_url: string;
    rating: number;
    text: string;
    relative_time_description: string;
}

interface Review {
    rating: number;
    valoracion: string;
    cantidad: number;
    reviews: ReviewItem[];
}

const reviewInitialStates: Review = {
    rating: 0,
    valoracion: '',
    cantidad: 0,
    reviews: []
};

const Reviews: React.FC = () => {
    const [reviews, setReviews] = useState<Review>(reviewInitialStates);

    useEffect(() => {
        const fetchReviews = async () => {
            try {
                const response = await axios.get(
                    // `https://maps.googleapis.com/maps/api/place/details/json?place_id=${PLACE_ID}&fields=reviews&key=${GOOGLE_PLACES_API_KEY}`
                    '/reviews.json'
                );
                if (response.data.result && response.data.result.reviews) {
                    setReviews({
                        ...reviews,
                        reviews: response.data.result.reviews
                    });
                } else {
                    console.error('No data found for resource with given identifier');
                }
            } catch (error) {
                console.error('Error fetching reviews:', error);
            }
        };

        fetchReviews();
    }, []);

    const groupReviews = (reviews: ReviewItem[], groupSize: number) => {
        const groupedReviews = [];
        for (let i = 0; i < reviews.length; i += groupSize) {
            groupedReviews.push(reviews.slice(i, i + groupSize));
        }
        return groupedReviews;
    };

    const groupedReviews = groupReviews(reviews.reviews, 3);

    return (
        <div className="d-flex flex-column align-items-center justify-content-center">
            <div className="display-5 fw-medium mb-4">Nuestros clientes cuentan</div>
            <div>
                <div className="fs-6 mb-2">
                    <div className="fs-5 text-center fw-bold mb-2">Muy bueno</div>
                    <div>
                        <span className="align-middle">
                            <span className="material-symbols-outlined" style={{ color: "#EAC452" }}>star</span>
                            <span className="material-symbols-outlined" style={{ color: "#EAC452" }}>star</span>
                            <span className="material-symbols-outlined" style={{ color: "#EAC452" }}>star</span>
                            <span className="material-symbols-outlined" style={{ color: "#EAC452" }}>star</span>
                            <span className="material-symbols-outlined" style={{ color: "#EAC452" }}>star_half</span>
                        </span> <b>(4.7)</b>
                    </div>
                    <span> Basado en <b>97</b> opiniones</span>
                </div>
            </div>
            <Row className="w-100 d-flex align-items-center justify-content-center flex-column">
                {!!reviews && reviews.reviews.length > 0 ? (
                    <Carousel className='w-100 mb-5' variant="dark" indicators={false} interval={5000}>
                        {groupedReviews.map((group, index) => (
                            <Carousel.Item key={index}>
                                <div className="d-flex align-items-center justify-content-center py-4">
                                    {isMobile
                                        ? (
                                            <Card key={group[0].author_name} className="border-0 border-2 border-bottom border-primary shadow mx-2" style={{ width: '300px' }}>
                                                <Card.Body className="p-4 p-3">
                                                    <figure>
                                                        <Card.Img
                                                            variant="top"
                                                            src={group[0].profile_photo_url}
                                                            alt={group[0].author_name}
                                                            className="img-fluid rounded rounded-circle mb-4 border border-5"
                                                            loading="lazy"
                                                            style={{
                                                                width: "50px",
                                                                height: "50px",
                                                                objectFit: "cover"
                                                            }}
                                                        />
                                                        <h6>{group[0].author_name}</h6>
                                                        <figcaption>
                                                            <div className="bsb-ratings text-warning mb-2">
                                                                {[...Array(5)].map((star, i) => (
                                                                    <i key={i} className={`fas fa-star fa-sm ${i < group[0].rating ? 'text-warning' : ''}`}></i>
                                                                ))}
                                                            </div>
                                                            <small className="d-block bsb-blockquote-icon mb-2" style={{ height: '60px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                                {group[0].text}
                                                            </small>
                                                            <small className="d-block text-secondary">{group[0].relative_time_description}</small>
                                                        </figcaption>
                                                    </figure>
                                                </Card.Body>
                                            </Card>
                                        )
                                        : group.map((review, idx) => (
                                            <Card key={idx} className="border-0 border-2 border-bottom border-primary shadow mx-2" style={{ width: '300px' }}>
                                                <Card.Body className="p-4 p-3">
                                                    <figure>
                                                        <Card.Img
                                                            variant="top"
                                                            src={review.profile_photo_url}
                                                            alt={review.author_name}
                                                            className="img-fluid rounded rounded-circle mb-4 border border-5"
                                                            loading="lazy"
                                                            style={{
                                                                width: "50px",
                                                                height: "50px",
                                                                objectFit: "cover"
                                                            }}
                                                        />
                                                        <h6>{review.author_name}</h6>
                                                        <figcaption>
                                                            <div className="bsb-ratings text-warning mb-2">
                                                                {[...Array(5)].map((star, i) => (
                                                                    <i key={i} className={`fas fa-star fa-sm ${i < review.rating ? 'text-warning' : ''}`}></i>
                                                                ))}
                                                            </div>
                                                            <small className="d-block bsb-blockquote-icon mb-2" style={{ height: '60px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                                {review.text}
                                                            </small>
                                                            <small className="d-block text-secondary">{review.relative_time_description}</small>
                                                        </figcaption>
                                                    </figure>
                                                </Card.Body>
                                            </Card>
                                        ))}
                                </div>
                            </Carousel.Item>
                        ))}
                    </Carousel>
                ) : (
                    <Col xs={12}>No hay reseñas disponibles.</Col>
                )}
            </Row>
        </div>
    );
};

export default Reviews;