import React from 'react';
import { ListGroup } from 'react-bootstrap';
import AppConstants from './constants/AppConstants';
import BasicPage from './components/BasicPage';

const PrivacyPolicy: React.FC = () => (
    <BasicPage>
        <div>
            <h1 className="display-4 mb-4">Política de Privacidad</h1>
            <p className="lead">
                En <b>OLBAR</b>, valoramos tu privacidad y queremos asegurarte que no recopilamos ningún tipo de dato personal en nuestra página web. No utilizamos cookies, ni almacenamos información de los visitantes, garantizando así que tu experiencia en nuestro sitio sea completamente anónima.
            </p>
            <p className="lead">
                Nuestra página está diseñada para ser accesible para todo el público, sin importar la edad o el lugar de acceso. Nos esforzamos por ofrecer una experiencia segura y confiable para todos nuestros usuarios.
            </p>
            <p className="lead">
                Si deseas ponerte en contacto con nosotros, puedes hacerlo a través de los siguientes medios:
            </p>
            <ListGroup variant="flush" className="mb-4">
                <ListGroup.Item className="lead">
                    <strong>Correo electrónico:</strong> <a href={"mailto:" + AppConstants.EMAIL} className="text-decoration-none">{AppConstants.EMAIL}</a>
                </ListGroup.Item>
                <ListGroup.Item className="lead">
                    <strong>Teléfono:</strong> <a href={"tel:" + AppConstants.PHONE} className="text-decoration-none">{AppConstants.PHONE}</a>
                </ListGroup.Item>
            </ListGroup>
            <p className="lead fw-medium">
                Para cualquier consulta o comentario, no dudes en comunicarte con nosotros. Estamos aquí para ayudarte.
            </p>
        </div>
    </BasicPage>
);

export default PrivacyPolicy;