import React from 'react';
import { Col, Row } from 'react-bootstrap';
import olbarLogo from '../../assets/olbar-logo.png';
import AppConstants from '../constants/AppConstants';

const Footer: React.FC = () => {
    return (
        <div className="w-100 bg-dark">
            <div className="d-flex align-items-center pt-4">
                <Row className="w-100 align-items-start">
                    {/* Logo Section */}
                    <Col md={4} className="h-100 d-flex justify-content-center align-items-center m-auto">
                        <img src={olbarLogo} alt="Logo" style={{ width: "200px", height: "200px" }} />
                    </Col>
                    {/* Legales Section */}
                    <Col md={4} className="d-flex justify-content-center align-items-center mb-3">
                        <div className="d-flex flex-column align-items-center">
                            <div className="display-6 olbar-title text-white mb-4 text-decoration-none">LEGALES</div>
                            <div className="w-100 d-flex flex-column align-items-center">
                                <a href="/privacy-policy" className="text-white mb-2 text-decoration-none">Políticas de Privacidad</a>
                                {/* <a href="/legal-notice" className="text-white mb-2 text-decoration-none">Aviso Legal</a> */}
                                <a href="/cookie-policy" className="text-white mb-2 text-decoration-none">Políticas de Cookies</a>
                            </div>
                        </div>
                    </Col>
                    {/* Contacto Section */}
                    <Col md={4} className="d-flex flex-column justify-content-center align-items-center text-center mb-3">
                        <div className="display-6 olbar-title text-white mb-4 text-decoration-none">CONTACTO</div>
                        <div className="w-100 d-flex flex-column align-items-center">
                            <div className="text-white mb-2">
                                <span className="fw-medium">Correo:</span> <a href={`mailto:${AppConstants.EMAIL}`} className="text-white">olbar.cafepub@example.com</a>
                            </div>
                            <div className="text-white mb-2">
                                <span className="fw-medium">Instagram:</span> <a href={AppConstants.INSTAGRAM} className="text-white">@olbar.cafepub</a>
                            </div>
                            <div className="text-white mb-2">
                                <span className="fw-medium">Ubicación:</span> <a href={AppConstants.GOOGLE_MAPS_URL} className="text-white">Calle de Belvis 3, Chamberi - Madrid</a>
                            </div>

                            <div className="text-white mb-2">
                                <span className="fw-medium">Teléfono:</span> <a href={"tel:" + AppConstants.PHONE} className="text-white">{AppConstants.PHONE}</a>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="w-100 text-center text-white py-4">
                <small>&copy; 2024 Arkane-T. Todos los derechos reservados.</small>
            </div>
        </div>
    );
};

export default Footer;