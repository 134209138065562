import { Fade } from 'react-awesome-reveal';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import AppConstants from './constants/AppConstants';

const Contacto = () => {
    return (
        <Container id="encuentranos" className="py-5">
            <div className="display-5 fw-medium text-center mb-5">Si nos buscas, nos encuentras</div>
            <div className="d-flex align-items-center justify-content-center">
                <div className="mb-5 py-2 px-3 bg-primary text-white shadow-sm">
                    <i className="fas fa-map-marker-alt me-3" aria-hidden="true"></i>
                    <span className="fw-medium">Calle de Belvis 3, Chamberi - Madrid</span>
                </div>
            </div>
            <Row className="mb-5">
                <Col md={8} >
                    <div className="border rounded-4 shadow overflow-hidden h-100 w-100">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3036.972620112763!2d-3.706439623406085!3d40.431605171437255!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd42290006b7d127%3A0xb8303434d8a44b3a!2sOLBAR!5e0!3m2!1ses-419!2ses!4v1723383420791!5m2!1ses-419!2ses"
                            width="100%"
                            height="100%"
                            frameBorder="0"
                            style={{ border: "0" }}
                            allowFullScreen
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </Col>
                <Col md={4} className="ps-md-5">
                    <Card className="border-0 h-100 rounded-0">
                        <Card.Body className="d-flex flex-column justify-content-between align-items-center py-5">
                            <Card.Subtitle className="dispaly-6 mb-4 fw-medium text-uppercase">Nuestros Horarios</Card.Subtitle>
                            <Card.Text className="mb-2 w-75 d-flex align-items-center justify-content-between">
                                <span className="fw-medium">Lunes</span> 8:30–24:00
                            </Card.Text>
                            <Card.Text className="mb-2 w-75 d-flex align-items-center justify-content-between">
                                <span className="fw-medium">Martes</span> 8:30–24:00
                            </Card.Text>
                            <Card.Text className="mb-2 w-75 d-flex align-items-center justify-content-between">
                                <span className="fw-medium">Miércoles</span> 8:30–24:00
                            </Card.Text>
                            <Card.Text className="mb-2 w-75 d-flex align-items-center justify-content-between">
                                <span className="fw-medium">Jueves</span> 18:00–24:00
                            </Card.Text>
                            <Card.Text className="mb-2 w-75 d-flex align-items-center justify-content-between">
                                <span className="fw-medium">Viernes</span> 8:30–2:00
                            </Card.Text>
                            <Card.Text className="mb-2 w-75 d-flex align-items-center justify-content-between">
                                <span className="fw-medium">Sábado</span> 10:00–2:00
                            </Card.Text>
                            <Card.Text className="w-75 d-flex align-items-center justify-content-between">
                                <span className="fw-medium">Domingo</span> 10:00–24:00
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col md={3}>
                    <Button variant="success" href={`${AppConstants.WHATSAPP}`} className="w-100" target='_blank'>
                        <i className="fa-brands fa-whatsapp fs-5 me-2 align-text-bottom"></i> WHATSAPP
                    </Button>
                    <div className="d-flex justify-content-between mt-4">
                        <a href={`mailto:${AppConstants.EMAIL}`} className="btn btn-outline-primary px-3 py-2" target='_blank'>
                            <i className="fas fa-envelope fs-5 align-text-bottom"></i>
                        </a>
                        <a href={AppConstants.INSTAGRAM} className="btn btn-outline-primary mx-2 px-3 py-2" target='_blank'>
                            <i className="fab fa-instagram fs-5 align-text-bottom"></i>
                        </a>
                        <a href={AppConstants.GOOGLE_MAPS_URL} className="btn btn-outline-primary px-3 py-2" target='_blank'>
                            <i className="fas fa-map-marker-alt fs-5 align-text-bottom"></i>
                        </a>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default Contacto;