import React, { useState, useEffect } from 'react';
import '../../styles/scss/menu-button.scss';

// MenuButton Props
interface MenuButtonProps {
    onClick: () => void;
    isActive?: boolean; // Prop isActive con valor por defecto
}

export default function MenuButton({ onClick, isActive = false }: MenuButtonProps) {
    const [active, setActive] = useState(isActive);

    useEffect(() => {
        setActive(isActive);
    }, [isActive]);

    const toggleActive = () => {
        onClick();
        setActive(!active);
    };

    return (
        <div className={`overlay ${active ? 'active' : ''}`} onClick={toggleActive}>
            <div className="button"></div>
        </div>
    );
};