import React from 'react';
import { ListGroup } from 'react-bootstrap';
import AppConstants from './constants/AppConstants';
import BasicPage from './components/BasicPage';

const CookiePolicy: React.FC = () => (
    <BasicPage>
        <div>
            <h1 className="display-4 mb-4">Política de Cookies</h1>
            <p className="lead">
                En <b>OLBAR</b> no utilizamos cookies en nuestra página web.
            </p>
            <p className="lead">
                Nuestra web está diseñada para proporcionar una experiencia segura y accesible sin necesidad de recopilar información a través de cookies. Esto significa que no se almacenan datos de navegación, preferencias del usuario ni se emplean herramientas de análisis que utilicen cookies.
            </p>
            <p className="lead">
                En caso de que en el futuro integremos el uso de cookies para mejorar nuestros servicios o por requerimientos técnicos, actualizaremos esta política e informaremos a nuestros usuarios de manera clara y transparente.
            </p>
            <p className="lead fw-medium">
                Si tienes alguna pregunta o inquietud sobre este tema, no dudes en contactarnos a través de nuestros medios de contacto disponibles en el sitio:
            </p>
            <ListGroup variant="flush" className="mb-4">
                <ListGroup.Item className="lead">
                    <strong>Correo electrónico:</strong> <a href={"mailto:" + AppConstants.EMAIL} className="text-decoration-none">{AppConstants.EMAIL}</a>
                </ListGroup.Item>
                <ListGroup.Item className="lead">
                    <strong>Teléfono:</strong> <a href={"tel:" + AppConstants.PHONE} className="text-decoration-none">{AppConstants.PHONE}</a>
                </ListGroup.Item>
            </ListGroup>
        </div>
    </BasicPage>
);

export default CookiePolicy;