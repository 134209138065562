import React, { useState } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import LogoParte1 from '../../assets/logoPartido1.png';
import LogoParte2 from '../../assets/logoPartido2.png';
import { Fade } from 'react-awesome-reveal';
import MenuButton from './MenuButton';

interface NavItemProps {
    href: string;
    children: React.ReactNode;
    style?: React.CSSProperties;
    onClick: () => void;
}

const NavItem: React.FC<NavItemProps> = ({ href, children, style, onClick }) => (
    <Nav.Item style={style}>
        <Nav.Link href={href} className="olbar-navlink navitemade-in" onClick={onClick}>
            {children}
        </Nav.Link>
    </Nav.Item>
);

const CustomNavbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [isOpening, setIsOpening] = useState(false);
    const [isClosing, setIsClosing] = useState(false);
    const [showMenuItems, setShowMenuItems] = useState(false);

    const toggleMenu = () => {
        if (isOpen) {
            setIsClosing(true);
            setIsOpen(false);
            setShowMenuItems(false);
        } else {
            setIsVisible(true);
            setIsOpening(true);
            setTimeout(() => setIsOpen(true), 10); // Pequeño retraso para permitir el renderizado
        }
    };

    const handleAnimationEnd = () => {
        if (isClosing) {
            setIsVisible(false);
            setIsClosing(false);
        }
        if (isOpening) {
            setIsOpening(false);
            setShowMenuItems(true);
        }
    };


    const navItems = [
        { href: '#home', label: 'Home' },
        { href: '#nosotros', label: 'Nosotros' },
        { href: '#tu-evento', label: 'Tu Evento' },
        { href: '#carta', label: 'Nuestra Carta' },
        { href: '#encuentranos', label: 'Encuentranos' }
    ];

    return (
        <>
            <Navbar
                id="navbar"
                expand={false} // Disable the default expand behavior
                className={`w-100 position-fixed top-0 right-0 px-5`}
                collapseOnSelect
                style={{ zIndex: 9999 }}
                data-bs-theme="dark"
            >
                <Navbar.Brand href="/" className="bg-black p-2 rounded-1 shadow">
                    <img className="d-block" src={LogoParte1} alt="Logo Parte 1" height="21px" width="51px" />
                    <img className="d-block mt-1" src={LogoParte2} alt="Logo Parte 2" height="21px" width="51px" />
                </Navbar.Brand>
                <MenuButton isActive={isOpen} onClick={toggleMenu} />
            </Navbar>
            {isVisible && (
                <div
                    className={`position-fixed w-100 vh-100 bg-black ${isOpening ? 'animate__animated animate__fadeInRight' : isClosing ? 'animate__animated animate__fadeOutRight' : ''}`}
                    style={{ zIndex: 9998 }}
                    onAnimationEnd={handleAnimationEnd}
                >
                    <div
                        className="fullscreen-menu position-absolute top-0 left-0 w-100 vh-100 bg-black d-flex align-items-center justify-content-center text-white"
                    >
                        <Nav className="flex-column text-center">
                            {showMenuItems && navItems.map((item, index) => (
                                <Nav.Item
                                    key={index}
                                    className="my-3"
                                    onClick={toggleMenu}
                                >
                                    <Nav.Link
                                        href={item.href}
                                        className="animate__animated animate__fadeIn fs-2 text-white"
                                        style={{ animationDelay: `${index === 0 ? 0 : index * 0.2}s` }}
                                    >
                                        {item.label}
                                    </Nav.Link>
                                </Nav.Item>
                            ))}
                        </Nav>
                    </div>
                </div>
            )}
        </>
    );
};

export default CustomNavbar;