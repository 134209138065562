import { Button, Card, Col, Container, ListGroup, Row } from "react-bootstrap";
import MetroMadridLogo from "../assets/logo-metro-madrid.png";
import AppConstants from "./constants/AppConstants";
import TERRAZA_IMG from "../assets/nuevas/IMG_2270.jpg";
import INTERIOR_IMG from "../assets/nuevas/IMG_1506.jpg";

export default function Comercial() {
    return (
        <Container className="pt-5">
            {/* La mejor terraza de Chamberi */}
            <Row id="nosotros" className="my-5">
                <Col md={5} className="mb-4">
                    <Card
                        className="w-100 rounded-4 border-0 mx-auto shadow"
                        style={{
                            height: "400px",
                            backgroundImage: `url(${TERRAZA_IMG})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            backgroundPositionY: "35%"
                        }}>
                    </Card>
                </Col>
                <Col className="ps-md-5">
                    <div className="display-6 fw-medium mb-4">La mejor terraza de Chamberí</div>
                    <div className="d-flex flex-column justify-content-between h-75">
                        <div>
                            <p className="lead mb-4">
                                Descubre nuestra terraza en <b>Chamberí</b>, uno de los barrios más vibrantes de Madrid. Estamos en <b><a href="https://maps.app.goo.gl/8o42PKRBVfPHbixD6" target="_blank" rel="noopener noreferrer" className="text-decoration-none">Calle de Belvis 3</a>, esquina con Fuencarral</b>, a pasos del <b>Cinesa Proyecciones</b> y <b>el Cine Paz</b>.
                            </p>
                            <p className="lead mb-5 fw-medium">
                                Disfruta de un ambiente acogedor y moderno, perfecto para jóvenes y familias. Saborea las mejores tapas y reserva tu mesa. ¡Te esperamos cerca de las estaciones Quevedo y Bilbao!
                            </p>
                        </div>
                        {/* Metros */}
                        <div className="d-flex">
                            <div className="d-flex justify-content-center align-items-center mx-3">
                                <img src={MetroMadridLogo} alt="logo-metro-madrid" style={{ width: "80px" }} />
                            </div>
                            <ListGroup variant="flush">
                                <ListGroup.Item className="d-flex align-items-center">
                                    <i className="fas fa-subway me-2"></i>
                                    <span>Estación de Metro <b>Quevedo</b> (L2): menos de 5 minutos a pie</span>
                                </ListGroup.Item>
                                <ListGroup.Item className="d-flex align-items-center">
                                    <i className="fas fa-subway me-2"></i>
                                    <span>Estación de Metro <b>Bilbao</b> (L1, L4): menos de 5 minutos a pie</span>
                                </ListGroup.Item>
                            </ListGroup>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <div className="text-center">
                        <Button
                            className="custom-button btn-lg"
                            size="lg"
                            href={AppConstants.WHATSAPP}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            HAZ TU RESERVA
                        </Button>
                    </div>
                </Col>
            </Row>
            {/* Nuestro interior, tu evento */}
            <Row id="tu-evento" className="my-5">
                <Col className="ps-md-5 d-flex flex-column justify-content-around">
                    <div>
                        <div className="display-6 fw-medium mb-4">Nuestro interior, tu evento</div>
                        <p className="lead">Nuestro <b>ambiente curado</b> ofrece un espacio ideal para <b>eventos privados y corporativos</b>.</p>
                        <p className="lead">Celebra <b>cumpleaños, despedidas, reuniones de empresa</b> y más en un entorno moderno y acogedor. </p>
                    </div>
                    <p className="lead fw-medium"> Disfruta de nuestra selección de tapas y bebidas, y personaliza tu evento con música y decoración a tu gusto.</p>

                </Col>
                <Col md={6} className="mt-4">
                    <Card
                        className="w-100 rounded-4 border-0 mx-auto shadow"
                        style={{
                            height: "400px",
                            backgroundImage: `url(${INTERIOR_IMG})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            backgroundPositionY: "15%"
                        }}>
                    </Card>
                </Col>
            </Row>
            {/* <Reviews /> */}
        </Container>
    );
};